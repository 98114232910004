import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { Link } from '@intractive/gatsby-plugin-react-intl'
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from '../../../components/Layout'

// eslint-disable-next-line
const style2 = require('./index.module2.css')
const style = require('./index.module.css')

const PageLijst = ({ data }) => {
  const page = data.pageData
  const parent = data.parentData

  const { title, metaDescription, pageTitle } = page

  const lijst = data.lijstData

  return (
    <Layout>
      <Helmet>
        <title>{title} · STIP</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <div className="row main-content">
        <div className="large-3 medium-3 columns sidebar hide-for-small">
          <div className="nav-sub">
            <ul>
              <li>
                <Link to={`/${parent.slug}/`} activeClassName={style.active}>
                  {parent.title}
                </Link>
                <ul>
                  {parent.subpages &&
                    parent.subpages.map(function (subpage, index) {
                      return (
                        <li key={index}>
                          <Link
                            to={`/${parent.slug}/${subpage.slug}/`}
                            activeClassName={style.active}
                          >
                            {subpage.pageTitle}
                          </Link>
                        </li>
                      )
                    })}
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div className="large-9 medium-9 columns">
          <h1>{pageTitle ? pageTitle : title}</h1>

          {lijst.kandidaten && (
            <ol type="1" className={style.ordered_list}>
                  <div className={style.lijst}>
                  {lijst.kandidaten.map(function (kandidaat, index) {
                    return (
                        <div className={style.lijster}>
                            <Link to={["/mensen/lijst/", kandidaat.slug, "/"].join("")}>
                                {kandidaat.lijstfoto ?
                                  (<GatsbyImage
                                      image={kandidaat.lijstfoto.gatsbyImageData}
                                      alt={kandidaat.name}
                                      className="blog-image"
                                  />) :
                                  (<img
                                      alt={kandidaat.name}
                                      src="https://images.ctfassets.net/xr7ntapvnai4/4zBG7Q3cqOguCwgXuEkMmp/558aa8dddc2c32c8196209098faa5b04/img-person-placeholder.jpg?w=300&h=300&q=50&fm=webp"
                                  />)
                                }
                                <li>
                                    {kandidaat.name}
                                </li>
                            </Link>
                        </div>
                    )
                  })}
                </div>
            </ol>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default PageLijst

export const query = graphql`
  query($node_locale: String) {
    pageData: contentfulSubpage(slug: { eq: "lijst" }, node_locale: {eq: $node_locale}) {
      title
      metaDescription
      pageTitle
      text {
        childMarkdownRemark {
          html
        }
      }
    }
    parentData: contentfulPagePersonen(node_locale: {eq: $node_locale}) {
      title
      slug
      subpages {
        slug
        pageTitle
        title
      }
    }
    lijstData: contentfulKandidatenlijst(node_locale: {eq: $node_locale}) {
      title
      kandidaten {
        name
        id
        slug
        lijstfoto {
            gatsbyImageData(formats: WEBP, width: 300)
        }
      }
    }
  }
`
